<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('tools.label-skeleton.title')" />
    </ion-header>
    <ion-content :fullscreen="true" scrollY="false">
      <div id="main-container">
        <input ref="fileChooser" @change="loadVideo($event.target.files)" type="file" accept="video/*"/>
        <input ref="labelFileChooser" @change="loadLabelFile($event.target.files)" type="file" accept=".json,application/json"/>
        <div id="selection-header">
          <ion-button 
            @click="labelFileChooser.click()"
            :fill="(loadedFramesMetadata != null) ? 'solid' : 'outline'">
            <ion-icon slot="icon-only" :icon="images">
            </ion-icon>
          </ion-button>
          <ion-item button @click="fileChooser.click()" lines="inset">
            <ion-label v-if="videoFile != null">{{ videoFile.name }}</ion-label>
            <ion-label v-else>{{ i18n.$t('tools.label-skeleton.choose-file') }}</ion-label>
          </ion-item>
          <ProgressButton 
            @click="estimatePose()"
            fill="solid"
            :loading="estimatingPose"
            :disabled="currentSelectedLabelingFrameIndex === null"
            spinner_name="circles">
            <ion-icon slot="icon-only" :icon="ai_analyse">
            </ion-icon>
          </ProgressButton>
          <ion-button 
            @click="shouldShowConnectedSkeleton = !shouldShowConnectedSkeleton"
            :fill="shouldShowConnectedSkeleton ? 'solid' : 'outline'">
            <ion-icon slot="icon-only" :icon="analytics">
            </ion-icon>
          </ion-button>
        </div>
        <div id="video-container">
            <!-- Enable interaction in canvas only if an image is selected for labeling -->
            <PointCanvas 
              ref="pointCanvasInstance"
              :drawingSource="canvasDrawingSource"
              :sourceSize="canvasSourceSize"
              :currentFrameObject="currentVisibleFrame"
              :zoomLevel="zoomLevel"
              :enableInteraction="currentSelectedLabelingFrameIndex != null && singleFrameStorage != null"
              @addpoint="addPointToFrame(currentVisibleFrame, $event.key, $event.x, $event.y, $event.marker)"
              @movepoint="movePointInFrame(currentVisibleFrame, $event.key, $event.x, $event.y)">
            </PointCanvas>
            <video @seeked="pointCanvasInstance.updateCanvas()" @loadedmetadata="loadedVideoMetadata()" ref="videoPlayerInstance"></video>
            <img id="single-frame-storage" ref="singleFrameStorage">
        </div>
        <ion-item lines="none" v-if="currentSelectedLabelingFrameIndex != null">
          <!-- Buttons needed for labeling current Frame -->
          <div class="interaction-container">
            <h4>
              <ion-item class="current-marker-select-item" @click="openSkeletonPointSelect()" :button="true" lines="none" detail="false">
                <ion-label class="text-with-icon" v-if="selectedSkeletonMarkerKey !== null">
                  <ion-icon 
                    slot="start" 
                    :icon="ellipse" 
                    :style="'color: '+selectedSkeletonMarkerColor">
                  </ion-icon>
                  {{ selectedSkeletonMarkerKey }}
                </ion-label>
                <ion-label v-else>
                  {{ i18n.$t('tools.label-skeleton.select-skeleton-marker') }}
                </ion-label>
              </ion-item>
              <ion-buttons>
                <ion-button fill="solid" color="primary" @click="previousMarker()">
                  <ion-icon slot="icon-only" :icon="caretBack"></ion-icon>
                </ion-button>
                <ion-button fill="solid" color="danger" @click="deleteMarker(selectedSkeletonMarkerKey)">
                  <ion-icon slot="icon-only" :icon="trash"></ion-icon>
                </ion-button>
                <ion-button fill="solid" color="primary" @click="nextMarker()">
                  <ion-icon slot="icon-only" :icon="caretForward"></ion-icon>
                </ion-button>
                <ion-button fill="outline" color="success" @click="finishCurrentFrame()">
                  <ion-icon slot="icon-only" :icon="returnDownBack"></ion-icon>
                </ion-button>
              </ion-buttons>
            </h4>
            <!-- Elements needed for labeling the selected frame -->
            <div class="interaction-elements">
              <ion-range min="1" :max="MAXZOOM" color="secondary" v-model="zoomLevel" :step="ZOOMSTEP" snap>
                <ion-label slot="start">1x</ion-label>
                <ion-label slot="end">{{ zoomLevel.toFixed(2) }}x / {{ MAXZOOM }}x</ion-label>
              </ion-range>
            </div>
          </div>
        </ion-item>
        <ion-item lines="none" v-else-if="videoLength > 0 || (savedFrames != null && savedFrames.length > 0)">
          <div class="interaction-container">
            <h4>
              {{ i18n.$t('tools.label-skeleton.choose-frame') }}
              <ion-buttons>
                <ion-button fill="outline" color="danger" @click="triggerReset()">
                  <ion-icon slot="icon-only" :icon="close"></ion-icon>
                </ion-button>
                <ion-button fill="outline" @click="openFrameList()">
                  <ion-icon slot="icon-only" :icon="listOutline"></ion-icon>
                </ion-button>
                <ion-button fill="solid" color="tertiary" @click="exportLabels()" :disabled="savedFrames.length === 0">
                  <ion-icon slot="icon-only" :icon="downloadOutline"></ion-icon>
                </ion-button>
                <ion-button fill="solid" color="success" @click="chooseCurrentFrame()">
                  <ion-icon slot="icon-only" :icon="arrowForwardOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </h4>
            <!-- Elements needed for choosing labeling frame -->
            <div :class="(videoLength > 0) ? 'interaction-elements' : 'interaction-elements hidden-interaction'">
              <ion-buttons>
                <ion-button fill="solid" shape="round" size="default" class="skip-button" color="primary" @click="currentVideoPosition -= VIDEOSTEP">
                  <ion-icon slot="icon-only" :icon="playSkipBackOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
              <ion-range min="0" :max="videoLength" color="secondary" v-model="currentVideoPosition" :step="VIDEOSTEP" snap>
                <ion-label slot="start">0:00</ion-label>
                <ion-label slot="end">{{ formatFrameTime(currentVideoPosition) }}
                  / 
                  {{ Math.trunc(videoLength / 60) }}:{{ addLeadingZeros(Math.trunc(videoLength % 60), 2) }}</ion-label>
              </ion-range>
              <ion-buttons>
                <ion-button fill="solid" shape="round" size="default" class="skip-button" color="primary" @click="currentVideoPosition += VIDEOSTEP">
                  <ion-icon slot="icon-only" :icon="playSkipForwardOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </div>
          </div>
        </ion-item>
      </div>

      <a ref="downloadElement" class="invisible-input" target="_blank"></a>

      <ion-modal
        :is-open="frameListDialogOpen"
        @didDismiss="frameListDialogOpen = false">
        <ion-page>
          <ion-header translucent>
            <ion-toolbar>
              <ion-title>
                {{ i18n.$t('tools.label-skeleton.frame-list.title') }} ({{ savedFrames.length }})
              </ion-title>
              <ion-buttons slot="end">
                <ion-button @click="frameListDialogOpen = false">{{ i18n.$t('default_interaction.close') }}</ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

          <ion-content id="frame-list-container">
            <ion-list ref="frameListRef" lines="full">
              <ion-item-sliding v-for="(frame, index) in savedFrames" :key="index">
                <ion-item 
                  @click="if (frame.imageFrameTime) currentVideoPosition = frame.imageFrameTime; frameListDialogOpen = false;"
                  button="true"
                  detail="true">
                    {{ i18n.$t('tools.label-skeleton.frame-list.frame-time') }}: 
                    {{ formatFrameTime(frame.imageFrameTime) }}
                    ({{ Object.entries(frame.skeleton).length }} {{ i18n.$t('tools.label-skeleton.frame-list.marker') }})
                </ion-item>

                <ion-item-options side="end">
                  <ion-item-option @click="closeSlidingItems();openDeleteFrameConfirmation(index);" color="danger"><ion-icon slot="icon-only" :icon="trash"></ion-icon></ion-item-option>
                </ion-item-options>
              </ion-item-sliding>
            </ion-list>
          </ion-content>
        </ion-page>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage,
  IonHeader,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
  IonButtons,
  IonButton,
  IonRange,
  IonModal,
  IonToolbar,
  IonTitle,
  IonList,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,
  menuController,
  alertController 
} from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';
import PointCanvas from '@/components/PointCanvas.vue';
import ProgressButton from '@/components/ProgressButton.vue';

import ai_analyse from '@/assets/icons/ai_analyse.svg';

import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

import { apiErrorToast } from '@/utils/error';

import { 
  images,
  playSkipBackOutline,
  playSkipForwardOutline,
  caretBack,
  caretForward,
  trash,
  arrowForwardOutline,
  listOutline,
  downloadOutline,
  returnDownBack,
  ellipse,
  analytics,
  close
} from 'ionicons/icons';

import { useI18n } from '@/utils/i18n';
import { localError } from '@/utils/error';
import { base64ToBlob, blobToBase64 } from '@/utils/media';

import _ from 'lodash';

export default  {
  name: 'LabelSkeleton',
  components: { IonHeader,
    IonContent,
    IonPage,
    MainToolbar,
    PointCanvas,
    IonItem,
    IonIcon,
    IonLabel,
    IonButtons,
    IonButton,
    IonRange,
    IonModal,
    IonToolbar,
    IonTitle,
    IonList,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    ProgressButton
  },
  setup() {
    const i18n = useI18n();

    const store = useStore();

    const URL = window.URL;

    const fileChooser = ref(null);

    const labelFileChooser = ref(null);

    const pointCanvasInstance = ref(null);

    const videoPlayerInstance = ref(null);

    const singleFrameStorage = ref(null);

    const canvasSourceSize = ref(null);

    const canvasDrawingSource = ref(null);

    const videoFile = ref(null);
    const videoLength = ref(0);
    const currentVideoPosition = ref(0);

    const VIDEOSTEP = 0.04; //TODO Maybe adapt to actaul framerate
    const SAME_FRAME_RADIUS = VIDEOSTEP / 2; //Distance to the actual frameTime to search for already labeled frames

    const EXPORT_DECIMAL_PLACES = 4;

    const ZOOMSTEP = 0.1;
    const MAXZOOM = 10;
    const zoomLevel = ref(1);

    const savedFrames = ref([]);
    const currentVisibleFrameIndex = ref(null);

    const loadedFramesMetadata = ref(null);

    const frameListDialogOpen = ref(false);
    const frameListRef = ref(null); 

    const downloadElement = ref(null);

    const currentSelectedLabelingFrameIndex = ref(null);

    const videoMetadataWithDimensions = computed(() => {
      if (videoFile.value != null){
        return {
          ..._.pick(videoFile.value, ['name', 'size']),
          dimensions: canvasSourceSize.value
        };
      }
      return null;
    });

    const metadata = computed(() => {
      if (videoMetadataWithDimensions.value != null){
        return videoMetadataWithDimensions.value;
      } else if (loadedFramesMetadata.value != null) {
        return {
          ...loadedFramesMetadata.value,
          dimensions: canvasSourceSize.value
        };
      } else {
        return null;
      }
    });

    const currentVisibleFrame = computed(() => {
      if (currentVisibleFrameIndex.value !== null && currentVisibleFrameIndex.value < savedFrames.value.length && currentVisibleFrameIndex.value >= 0){
        return savedFrames.value[currentVisibleFrameIndex.value];
      } else {
        return null;
      }
    });

    const updateCanvasDrawingSource = function(){
      if (singleFrameStorage.value != null && singleFrameStorage.value.src != null && singleFrameStorage.value.src != '//:0' && singleFrameStorage.value.complete){
        canvasDrawingSource.value = null;
        canvasDrawingSource.value = singleFrameStorage.value;
      } else if (videoLength.value > 0) {
        canvasDrawingSource.value = videoPlayerInstance.value;
      } else {
        canvasDrawingSource.value = null;
      }
    }

    const loadedLabelFile = function(labelFileContents){
      try {
        let loadedLabelFileObject = JSON.parse(labelFileContents.target.result);
        if (loadedLabelFileObject.sourceDimensions != null && typeof loadedLabelFileObject.sourceFileName === 'string') {
          let newDimensions = { 'width': parseInt(loadedLabelFileObject.sourceDimensions.width), 'height': parseInt(loadedLabelFileObject.sourceDimensions.height) };
          let newMetadata = {
            'name': loadedLabelFileObject.sourceFileName,
            'size': parseInt(loadedLabelFileObject.sourceFileSize)
          };

          //Compare new metadata and image dimensions with current ones and only load if the match or if no metadata has yet been loaded
          //Prevents wrong files and dimensions of labels and video source to be combined
          if (videoMetadataWithDimensions.value == null || _.isEqual(videoMetadataWithDimensions.value, {...newMetadata, dimensions: newDimensions})){ //Compare metadata including dimensions
            loadedFramesMetadata.value = newMetadata;
            canvasSourceSize.value = newDimensions;
            if (Array.isArray(loadedLabelFileObject.frames)){ //Check if frames is an array
              savedFrames.value = loadedLabelFileObject.frames;

              for (let frame of savedFrames.value) {
                if (frame.imageBase64 != null) {
                  let fileComponents = frame.imageBase64.split(',');

                  if (fileComponents.length === 2) {
                    let firstDataURIPart = fileComponents[0].split(',')[0].split(':');
                    if (firstDataURIPart.length === 2){
                      let fileMime = firstDataURIPart[1].split(';')[0];

                      frame.imageBlob = base64ToBlob(fileComponents[1], fileMime);
                    }
                  }
                }
              }
              
              //If we loaded a frame list, then go the first frame
              if (savedFrames.value != null && savedFrames.value.length > 0) {
                currentVideoPosition.value = savedFrames.value[0].imageFrameTime;
              }
            }
          } else {
            localError(i18n, i18n.$t('tools.label-skeleton.label-file-not-matching'));
          }
        } else {
          localError(i18n, i18n.$t('tools.label-skeleton.label-file-invalid'));
        }
      } catch {
        localError(i18n, i18n.$t('tools.label-skeleton.label-file-invalid'));
      }
    }

    const loadLabelFile = function(labelFiles){
      if (labelFiles.length == 1){
        try {
          let reader = new FileReader();
          reader.onload = loadedLabelFile;
          reader.readAsText(labelFiles[0]);
        } catch {
          localError(i18n, i18n.$t('tools.label-skeleton.label-file-invalid'));
        }
      }
    }

    const loadVideo = function(videoFiles){
      if (videoFiles.length == 1 && videoPlayerInstance.value != null){
        if (videoPlayerInstance.value.src != null) URL.revokeObjectURL(videoPlayerInstance.value.src);
        videoFile.value = videoFiles[0];
        if (videoPlayerInstance.value.canPlayType(videoFile.value.type) !== ''){
          //Reset video related parameters
          canvasDrawingSource.value = null;
          videoLength.value = 0;
          currentVideoPosition.value = 0;

          let videoURL = URL.createObjectURL(videoFile.value);
          videoPlayerInstance.value.src = videoURL;
        } else {
          localError(i18n, i18n.$t('tools.label-skeleton.file-type-not-supported'));
          //Reset on error
          resetLabelingState();
        }
      }
    }

    const resetLabelingState = function(){
      fileChooser.value.value = null; 
      videoFile.value = null;
      videoPlayerInstance.value.removeAttribute('src');
      videoPlayerInstance.value.load();

      canvasDrawingSource.value = null;
      videoLength.value = 0;
      currentVideoPosition.value = 0;
      resetFrameParameters();
    }

    const resetFrameParameters = function(){
      loadedFramesMetadata.value = null;
      labelFileChooser.value.value = null;
      singleFrameStorage.value.src = '//:0';
      savedFrames.value = [];
      currentSelectedLabelingFrameIndex.value = null;
      currentVisibleFrameIndex.value = null;
      canvasSourceSize.value = null;
      zoomLevel.value = 1;
    }

    const loadedVideoMetadata = function(){
      let newDimensions = { 'width': videoPlayerInstance.value.videoWidth, 'height': videoPlayerInstance.value.videoHeight };

      //Reset the frame parameters only if metadata of new video differs from currently loaded frames - keep loaded frame list it is from the loaded video
      if (!_.isEqual(metadata.value, {...loadedFramesMetadata.value, dimensions: newDimensions})){ //New dimensions is compared with old ones that are still set
        resetFrameParameters();
      }
      
      //Has to be only set once, since all frames have the size of the video itself
      canvasSourceSize.value = newDimensions;
      //Set length and initial position (1st step)
      videoLength.value = videoPlayerInstance.value.duration;
      currentVideoPosition.value = VIDEOSTEP;
      //Set drawing source initially when video has loaded
      updateCanvasDrawingSource();
    }

    const formatFrameTime = function(frameTime){
      return Math.trunc(frameTime / 60) + 
      ':' + 
      addLeadingZeros(Math.trunc(frameTime % 60), 2) + 
      '.' + 
      frameTime.toFixed(2).toString().split('.')[1]; 
    }

    //Watch for changes in the slider for video seeking, and seek the actual video
    watch(currentVideoPosition, (newVideoPosition) => {
      if (videoPlayerInstance.value != null){
        videoPlayerInstance.value.currentTime = newVideoPosition;
      }

      //Get the current skeleton data to display in the canvas if there is any - also set the image if no video is loaded
      if (currentSelectedLabelingFrameIndex.value === null){ 
        /*If the mode is "select frame from video", so no frame is selected for labeling, 
          we search for a frame that matches the current time to display its markers, if there exists one*/
        let frameIndexAtCurrentTime = searchFrameIndexAtTime(newVideoPosition);
        if (frameIndexAtCurrentTime >= 0){
          currentVisibleFrameIndex.value = frameIndexAtCurrentTime;
          if (videoLength.value == 0){
            singleFrameStorage.value.src = savedFrames.value[frameIndexAtCurrentTime].imageBase64;
            //Drawing source is set in onload and can not be set here!
          }
        } else {
          currentVisibleFrameIndex.value = null;
          singleFrameStorage.value.src = '//:0';
          //This change does not trigger onload, so we refresh manually
          updateCanvasDrawingSource();
        }
      }
    });

    const addLeadingZeros = function(number, length){
      number = number.toString();
      while (number.length < length) number = '0' + number;
      return number;
    }

    //When the selected labeling frame is changed, selected or unselected, set the frameStorage accordingly and set the drawing source (onload is set in onMounted)
    watch(currentSelectedLabelingFrameIndex, (newFrame) => {
      if (newFrame != null){
        singleFrameStorage.value.src = savedFrames.value[newFrame].imageBase64;
        //Drawing source is set in onload and can not be set here!
      } else if (videoLength.value > 0) { //Reset the frame only, when we have a video loaded
        singleFrameStorage.value.src = '//:0';
        //This change does not trigger onload, so we refresh manually
        updateCanvasDrawingSource();
      }
    })

    /* Returns the index of a frame if it matches this time or -1 if none is found */
    const searchFrameIndexAtTime = function(frameTime){
      for (let index in savedFrames.value){
        if (savedFrames.value[index]){
          if (frameTime < savedFrames.value[index].imageFrameTime + SAME_FRAME_RADIUS && frameTime > savedFrames.value[index].imageFrameTime - SAME_FRAME_RADIUS){
            return index;
          }
        }
      }
      return -1;
    }

    const shouldShowConnectedSkeleton = computed({
      get: () => store.getters['skeleton/shouldShowConnectedSkeleton'],
      set: newState => {
        store.commit('skeleton/setShowConnectedSkeleton', newState);
      }
    });

    

    /* Expose the selected marker of the store as computed value */
    const selectedSkeletonMarkerKey = computed({
      get: () => store.getters['skeleton/getSelectedSkeletonMarkerKey'],
      set: key => {
        store.dispatch('skeleton/setSelectedSkeletonMarkerKey', key);
      }
    });

    const selectedSkeletonMarkerColor = computed(() => {
      if (store.getters['skeleton/getSelectedSkeletonMarker'] !== null){
        return store.getters['skeleton/getSelectedSkeletonMarker'].color;
      } else {
        return 'white';
      }
    });

    //Create a new frame object with all the placeholders for labeling data
    const pushNewFrameObject = function(blob, frameTime){
      let newFrameObject = {
        'imageBlob': blob,
        'imageBase64': null,
        'imageFrameTime': frameTime,
        'skeleton': {}
      }

      blobToBase64(blob, (base64Blob)=> {
        newFrameObject.imageBase64 = base64Blob;
        savedFrames.value.push(newFrameObject);
        currentSelectedLabelingFrameIndex.value = savedFrames.value.length - 1; //Select latest frame as current frame
        currentVisibleFrameIndex.value = currentSelectedLabelingFrameIndex.value;
      });
    }

    //Extract the current frame of the video in its original resolution by creating a temporary canvas
    const chooseCurrentFrame = function(){
      let captureCanvas = document.createElement('canvas');

      captureCanvas.width = videoPlayerInstance.value.videoWidth;
      captureCanvas.height = videoPlayerInstance.value.videoHeight;
      captureCanvas.getContext('2d').drawImage(videoPlayerInstance.value, 0, 0);

      //Take exisiting frame at current position, if it exists
      if (currentVisibleFrameIndex.value !== null && currentVisibleFrameIndex.value >= 0){
        currentSelectedLabelingFrameIndex.value = currentVisibleFrameIndex.value;
      } else { //Create new frame
        //Calls pushNewFrameObject after the creation of the blob has finished
        captureCanvas.toBlob((blob) => pushNewFrameObject(blob, currentVideoPosition.value), 'image/png');
      }
    }

    const sortFrameListByFrameTime = function(){
      savedFrames.value.sort((frame1, frame2)=>{
        return frame1.frameTime - frame2.frameTime;
      })
    }

    const openFrameList = function(){
      sortFrameListByFrameTime();
      frameListDialogOpen.value = true;
    }

    const closeSlidingItems = function(){
      frameListRef.value.$el.closeSlidingItems();
    }

    const removeSingleFrame = function(index){
      if (currentVisibleFrameIndex.value === index) {
        currentVisibleFrameIndex.value = null;
      }
      savedFrames.value.splice(index, 1);
    }

    const openDeleteFrameConfirmation = async function(index){
      if (savedFrames.value[index]){
        const alert = await alertController
        .create({
          cssClass: 'delete-confirmation-alert',
          header: i18n.$t('tools.label-skeleton.delete-confirmation.title'),
          message: i18n.$t('tools.label-skeleton.delete-confirmation.message.before') + '<b>' + formatFrameTime(savedFrames.value[index].imageFrameTime) + '</b>' + i18n.$t('tools.label-skeleton.delete-confirmation.message.after'),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.label-skeleton.delete-confirmation.delete'),
              cssClass: 'delete-confirmation-okay',
              handler: () => {
                removeSingleFrame(index);
              },
            },
          ],
        });
        return alert.present();
      }
    }

    const exportLabels = async function(){
      sortFrameListByFrameTime();
      const alert = await alertController
        .create({
          cssClass: 'export-frames-alert',
          header: i18n.$t('tools.label-skeleton.export-dialog.title'),
          message: i18n.$t('tools.label-skeleton.export-dialog.message.before') + '<b>' + savedFrames.value.length + '</b>' + i18n.$t('tools.label-skeleton.export-dialog.message.after'),
          buttons: [
            {
              text: i18n.$t('default_interaction.cancel'),
              role: 'cancel'
            },
            {
              text: i18n.$t('tools.label-skeleton.export-dialog.export'),
              cssClass: 'export-frames-okay',
              handler: () => {
                if(metadata.value != null && savedFrames.value.length > 0){
                  let exportObject = {
                    sourceFileName: metadata.value.name,
                    sourceFileSize: metadata.value.size,
                    sourceDimensions: metadata.value.dimensions,
                    frames: savedFrames.value
                  }
                  let exportString = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObject, 
                    function(key, value) {
                      //Set as fixed number to reduce number of decimal places beyond what is realistic
                      if (typeof value === 'number') {
                        return parseFloat(value.toFixed(EXPORT_DECIMAL_PLACES));
                      }
                      if (key === 'imageBlob') { //Exclude blobs
                        return undefined;
                      }
                      return value;
                    },
                    2)
                  );

                  downloadElement.value.setAttribute('href', exportString);
                  downloadElement.value.setAttribute('download', metadata.value.name + '.json')
                  downloadElement.value.click();
                }
              },
            },
          ],
        });
      return alert.present();
    }
    
    const previousMarker = function(){
      store.commit('skeleton/tryDecrementSelectedSkeletonMarker');
    }

    const deleteMarker = function(key){
      delete savedFrames.value[currentSelectedLabelingFrameIndex.value].skeleton[key];
    }

    const nextMarker = function(){
      store.commit('skeleton/tryIncrementSelectedSkeletonMarker');
    }

    //Called when the current frame has been finished labeling
    const finishCurrentFrame = function(){
      let frame = savedFrames.value[currentSelectedLabelingFrameIndex.value];
      //Delete frame from list, if no skeleton was added!
      if (Object.keys(frame.skeleton).length === 0) {
        removeSingleFrame(currentSelectedLabelingFrameIndex.value);
      }
      //Everything is saved on the fly, so just return to frame selection
      currentSelectedLabelingFrameIndex.value = null;
      //Reset zoom when returning to video
      zoomLevel.value = 1;
      //Reset the skeletonMarker when leaving the current frame
      store.commit('skeleton/resetSelectedSkeletonMarker');
    }

    /* Opens the side menu for selecting skeleton point selection */
    const openSkeletonPointSelect = function(){
      menuController.open('side-component');
    }

    const addPointToFrame = function(frame, key, x, y, marker){
      let tmpMarker = {...marker}; //Spread marker to get a local copy
      delete tmpMarker.key; //Delete key to only leave all other properties in marker
      frame.skeleton[key] = { 
        x: x, y: y, ...tmpMarker //Spread all properties left to set it in this instance
      };
    }

    const movePointInFrame = function(frame, key, newX, newY){
      frame.skeleton[key].x = newX;
      frame.skeleton[key].y = newY;
    }

    /* Watch for changes in the definition and any of the properties to remove and readd any already placed points to set their key or properties.
    CloneDeep is required to get changes of reactive objects */
    watch(() => _.cloneDeep(store.getters['skeleton/getSkeletonDefinition']), (newDefinition, oldDefinition) => {
      //Do not react when the length changed (removed or added)
      if (newDefinition.length === oldDefinition.length){
        //Sort both definitions and check their equality to catch the case where a reorder happened, instead of property change
        const compareKeys = (a,b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0);

        //Only continue if they are not equal, else it just was a reorder (Spread to not change the array for later use)
        if (!_.isEqual([...newDefinition].sort(compareKeys), [...oldDefinition].sort(compareKeys))){

          //Check for every saved frame if a key was modified and update it
          for (let frame of savedFrames.value){
            for (let markerIndex = 0; markerIndex < newDefinition.length; markerIndex++){
              //Did something change in the current checked marker?
              if (!_.isEqual(newDefinition[markerIndex], oldDefinition[markerIndex])){                
                //Only continue if the key changed is placed in this frame
                if (oldDefinition[markerIndex].key in frame.skeleton) {
                  //Save instance for current properties
                  let currentSkeletonMarker = frame.skeleton[oldDefinition[markerIndex].key];

                  //Delete old key
                  delete frame.skeleton[oldDefinition[markerIndex].key];

                  //Readd key with new parameters
                  addPointToFrame(frame, newDefinition[markerIndex].key, currentSkeletonMarker.x, currentSkeletonMarker.y, newDefinition[markerIndex]);
                }
              }
            }
          }
        }
      }
    }, { deep: true });

    const estimatingPose = ref(false);

    const POSE_ESTIMATION_REPORT_TYPE_DESCRIPTOR = 'Horse Pose Estimation';

    const POSE_PROBABILITY_THRESHOLD = 0.1;

    const poseEstimationReportType = computed(() => { //TODO Make that better once we have the slugs!
      if (store.getters['reports/getReportTypeIndex'] !== null){
        for (let mainCategoryTypes of Object.values(store.getters['reports/getReportTypeIndex'])) {
          if (POSE_ESTIMATION_REPORT_TYPE_DESCRIPTOR in mainCategoryTypes){
            let typeVersions = mainCategoryTypes[POSE_ESTIMATION_REPORT_TYPE_DESCRIPTOR];
            return typeVersions.versions[typeVersions.newest_version];
          }
        }
      }
      return null;
    });

    const addAnalyses = function(frame, analyses){
      for (let analysis of analyses){
        if (analysis.ai_model && analysis.ai_model.method === 'pose' && analysis.pose){
          for (let [key, coordinates] of Object.entries(analysis.pose)){
            let marker = store.getters['skeleton/getSkeletonMarkerByKey'](key);
            if (coordinates.probability >= POSE_PROBABILITY_THRESHOLD && marker !== null && coordinates.x !== undefined && coordinates.y !== undefined){
              addPointToFrame(frame, key, coordinates.x, coordinates.y, marker);
            }
          }
          return;
        }
      }
    }

    const estimatePose = async function() {
      if (currentSelectedLabelingFrameIndex.value !== null && poseEstimationReportType.value) {
        let frame = savedFrames.value[currentSelectedLabelingFrameIndex.value];
        if (frame){
          estimatingPose.value = true;

          let report = {
            timestamp: Date.now(),
            type: poseEstimationReportType.value.id,
            location: 'stable',
            control_examination: false,
            fields: [
              {
                '__component': 'report-fields.text',
                'key': 'uncategorized->sourceFilename',
                'value': metadata.value.name
              },
              {
                '__component': 'report-fields.decimal',
                'key': 'uncategorized->imageFrameTime',
                'value': frame.imageFrameTime.toFixed(EXPORT_DECIMAL_PLACES)
              },
              {
                '__component': 'report-fields.image',
                'key': 'uncategorized->imageForPoseEstimation',
                'uploaded_at': Date.now() //TODO Remove when moved to server
              }
            ] 
          };

          //TODO Check if all fields are in the reportTypeDefinition

          let filePath = `fields[${report.fields.length-1}].file`;

          store.dispatch('reports/createNewReport', {
            report, 
            files: {
              [filePath]: new File([frame.imageBlob], `${frame.imageFrameTime.toFixed(EXPORT_DECIMAL_PLACES)}.png`) //Convert Blob to File with correct name
            }
          })
          .then((uploadStatus) => uploadStatus.completionPromise)
          .then((completeResponse) => completeResponse.id)
          .then((reportId) => {
            if (reportId == null) throw new Error('Error when uploading image for analysis');
            return store.dispatch('reports/analyseReport', reportId);
          })
          .then(async (analyses) => {
            if (Array.isArray(analyses)){
              //If at least one point in the skeleton is set, ask to overwrite
              if (Object.keys(frame.skeleton).length >= 1) {
                const alert = await alertController
                .create({
                  header: i18n.$t('tools.label-skeleton.overwrite-confirmation.title'),
                  message: i18n.$t('tools.label-skeleton.overwrite-confirmation.message'),
                  buttons: [
                    {
                      text: i18n.$t('default_interaction.cancel'),
                      role: 'cancel'
                    },
                    {
                      text: i18n.$t('tools.label-skeleton.overwrite-confirmation.overwrite'),
                      cssClass: 'overwrite-confirmation-okay',
                      handler: () => {
                        addAnalyses(frame, analyses);
                      },
                    },
                  ],
                });
                return alert.present();
              } else { //Else, just add it
                addAnalyses(frame, analyses);
              }
            }
          })
          .catch((error) => {
            apiErrorToast(i18n, error);
          })
          .finally(() => {
            estimatingPose.value = false;
          });
        }
      }
    }

    const triggerReset = async function(){
      const alert = await alertController
      .create({
        header: i18n.$t('tools.label-skeleton.reset-confirmation.title'),
        message: i18n.$t('tools.label-skeleton.reset-confirmation.message'),
        buttons: [
          {
            text: i18n.$t('default_interaction.cancel'),
            role: 'cancel'
          },
          {
            text: i18n.$t('tools.label-skeleton.reset-confirmation.reset'),
            cssClass: 'reset-confirmation-okay',
            handler: () => {
              resetLabelingState();
            },
          },
        ],
      });
      return alert.present();
    }

    onMounted(() => {
      //When a change in the stored image occurred, trigger refresh. If triggered earlier, the image might not be ready yet
      singleFrameStorage.value.onload = function(){
        updateCanvasDrawingSource();
        pointCanvasInstance.value.updateCanvas(); //Update canvas when a new image is loaded, because an update might not be triggered, when the instance stays the same.
      }
    });

    onUnmounted(() => {
        if (videoPlayerInstance.value != null && videoPlayerInstance.value.src != null) URL.revokeObjectURL(videoPlayerInstance.value.src);
    });

    return { 
      i18n,
      loadVideo,
      loadLabelFile,
      loadedVideoMetadata,
      loadedFramesMetadata,
      formatFrameTime,
      addLeadingZeros,
      shouldShowConnectedSkeleton,
      pointCanvasInstance,
      videoPlayerInstance,
      canvasSourceSize,
      singleFrameStorage,
      canvasDrawingSource,
      videoFile,
      videoLength,
      currentVideoPosition,
      VIDEOSTEP,
      ZOOMSTEP,
      MAXZOOM,
      zoomLevel,
      savedFrames,
      currentVisibleFrameIndex,
      currentVisibleFrame,
      frameListDialogOpen,
      frameListRef,
      downloadElement,
      openFrameList,
      closeSlidingItems,
      openDeleteFrameConfirmation,
      exportLabels,
      addPointToFrame,
      movePointInFrame,
      currentSelectedLabelingFrameIndex, 
      selectedSkeletonMarkerKey,
      selectedSkeletonMarkerColor,
      previousMarker,
      deleteMarker,
      nextMarker,
      fileChooser,
      labelFileChooser,
      chooseCurrentFrame,
      finishCurrentFrame,
      openSkeletonPointSelect,
      estimatingPose,
      estimatePose,
      triggerReset,
      images,
      playSkipBackOutline,
      playSkipForwardOutline,
      caretBack,
      caretForward,
      trash,
      arrowForwardOutline,
      listOutline,
      downloadOutline,
      returnDownBack,
      ellipse,
      analytics,
      ai_analyse,
      close
    };
  }
}
</script>

<style>
.delete-confirmation-okay, .overwrite-confirmation-okay, .reset-confirmation-okay {
  color: var(--ion-color-danger)!important;
}

.export-frames-okay {
  color: var(--ion-color-success-shade)!important;
}
</style>

<style scoped>
/* Invisible file input that gets called from another button. */
input[type=file], .invisible-input {
  visibility: hidden;
  display: none;
}

/* Flexbox container for children to fill all available space */
#main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
}

/* Relative container for absolute children to be independent within. Fills remaining space in between fully. */
#video-container {
  position: relative;
  /* Grow to maximum size but not smaller */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0px;
  background: var(--ion-item-background);
  border-bottom: 1px solid var(--ion-item-border-color);
  display: flex;
}

ion-item {
  /* Prevent items overlapping each other */
  flex-shrink: 0;
}

/* Video is invisible. It is drawn inside canvas when needed. */
video {
  display: none;
}

#single-frame-storage{
  display: none;
}

.interaction-container {
  display: flex;
  flex-flow: column;
  width: 100%;
}

.interaction-container h4 {
  margin-bottom: 0;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.interaction-container h4 ion-button {
  margin-left: 10px;
}

.interaction-elements {
  display: flex;
  align-items: center;
  width: 100%;
}

.hidden-interaction {
  visibility: hidden;
}

.skip-button {
  margin: 0;
}

.skip-button button {
  padding: 0;
}

.text-with-icon {
  display: flex;
  align-items: center;
}

.text-with-icon > ion-icon {
  display: inline-block;
  margin-right: 5px;
  min-width: 16px;
}

.current-marker-select-item {
  flex-grow: 1;
  flex-shrink: 1;
  --inner-padding-bottom: 0px;
  --inner-padding-top: 0px;
  --inner-padding-end: 0px;
  --padding-start: 1vw;
  --min-height: 32px;
  height: auto;
}

#selection-header {
  background-color: var(--ion-item-background);
  display: flex;
  align-items: center;
}

#selection-header ion-item {
  flex-shrink: 1;
  flex-grow: 1;
}

#selection-header ion-button {
  --padding-start: 10px;
  --padding-end: 10px;
  margin-right: 8px;
  margin-left: 14px;
}

#frame-list-container {
  --background: var(--ion-item-background);
}
</style>
