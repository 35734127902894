<template>
  <div id="canvas-size-reference" ref="canvasSizeReference"></div>
  <div id="canvas-container" ref="canvasContainer" :class="canvasInteracting ? 'interacting' : ''" :style="(zoomLevel <= 1) ? 'overflow: hidden' : ''">
      <canvas ref="canvasInstance"></canvas>
  </div>
</template>

<script>
import { createGesture } from '@ionic/vue';

import { onMounted, ref, watch, computed } from 'vue';
import { useStore } from 'vuex';

export default  {
  name: 'PointCanvas',
  props: {
    drawingSource: Object,
    sourceSize: Object,
    enableInteraction: Boolean,
    currentFrameObject: Object,
    zoomLevel: Number,
    disableFrameUpdate: {
      type: Boolean,
      default: false
    },
    alwaysShowSkeleton: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'addpoint',
    'movepoint'
  ],
  setup(props, context) {
    const store = useStore();

    const canvasContainer = ref(null);

    const canvasInstance = ref(null);

    const canvasSizeReference = ref(null);

    const canvasAvailableSpace = ref(null);

    const canvasInteracting = ref(false);

    const MARKER_RADIUS = 7;
    const LONG_PRESS_THRESHOLD = 500;
    const MARKER_INTERACTION_RADIUS = MARKER_RADIUS * 2; //Radius in which interaction with a marker is still detected

    const shouldShowConnectedSkeleton = computed(() => {
      //Either override setting or use the one from the settings
      if (props.alwaysShowSkeleton === true) {
        return true;
      } else {
        return store.getters['skeleton/shouldShowConnectedSkeleton'];
      }
    });

    //Update canvas when the visibility of the skeleton changes
    watch(shouldShowConnectedSkeleton, () => {
      updateCanvas();
    });

    //TODO Test fully that all functions still work as intended and the results are the same!!!

    //Give option to disable this update handler in case the update is called externally
    if (props.disableFrameUpdate != true){
      watch(() => props.currentFrameObject, () => {
        updateCanvas(); 
      }, { deep: true });
    }

    //Clear canvas when no drawing source is set
    watch(() => props.drawingSource, () => {
      if (props.drawingSource === null) {
        clearCanvas();
      } else {
        updateCanvas();
      }
    });

    const clearCanvas = function(){
      canvasInstance.value.getContext('2d').clearRect(0, 0, canvasInstance.value.width, canvasInstance.value.height);
    }

    /* Expose the selected marker of the store as computed value */
    const selectedSkeletonMarkerKey = computed({
      get: () => store.getters['skeleton/getSelectedSkeletonMarkerKey'],
      set: key => {
        store.dispatch('skeleton/setSelectedSkeletonMarkerKey', key);
      }
    });

    /* Called whenever view or content of canvas changes. */
    const updateCanvas = function(){
      if (props.drawingSource && props.drawingSource !== null) {
        canvasInstance.value.getContext('2d').drawImage(props.drawingSource, 0, 0, canvasInstance.value.width, canvasInstance.value.height);

        if (props.currentFrameObject && props.currentFrameObject !== null) {
          let ratio = canvasSourceDestinationRatio();

          let ctx = canvasInstance.value.getContext('2d');

          //Draw connection lines - Drawn first seperately to not overlap the dots
          for (let [key, marker] of Object.entries(props.currentFrameObject.skeleton)) {
            let canvasX = marker.x * ratio.ratioX;
            let canvasY = marker.y * ratio.ratioY;

            //Draw lines to all connected markers if enabled
            if (shouldShowConnectedSkeleton.value && marker.connectedTo) {
              ctx.lineWidth = 3;
              ctx.fillStyle = ctx.strokeStyle = marker.color || 'red';

              for (let connectionKey of marker.connectedTo){
                //If the connected marker is placed and not its own, draw a line to it
                if (key !== connectionKey && props.currentFrameObject.skeleton[connectionKey]) {
                  let connectedMarker = props.currentFrameObject.skeleton[connectionKey];

                  let canvasConnectedX = connectedMarker.x * ratio.ratioX;
                  let canvasConnectedY = connectedMarker.y * ratio.ratioY;

                  ctx.beginPath();
                  ctx.moveTo(canvasX, canvasY);
                  ctx.lineTo(canvasConnectedX, canvasConnectedY);
                  ctx.closePath();
                  ctx.stroke();
                }
              }
            }
          }

          for (let [key, marker] of Object.entries(props.currentFrameObject.skeleton)) {
            let canvasX = marker.x * ratio.ratioX;
            let canvasY = marker.y * ratio.ratioY;

            ctx.fillStyle = ctx.strokeStyle = marker.color || 'red';
            ctx.beginPath();
            ctx.arc(canvasX, canvasY, MARKER_RADIUS, 0, Math.PI * 2, true);
            ctx.fill();

            //If it is the currently selected marker and we are in labeling mode, highlight it with a border
            if (props.enableInteraction && selectedSkeletonMarkerKey.value === key){
              ctx.lineWidth = 3;
              ctx.beginPath();
              ctx.arc(canvasX, canvasY, MARKER_RADIUS + 5, 0, Math.PI * 2, true);
              ctx.stroke();
            }
          }
        }
      }
    }

    /* Listens for all resize events of the canvas reference and adjusts the canvas size accordingly */
    const canvasResizeObserver = new ResizeObserver(entries => {
      entries.forEach(entry => {
        canvasAvailableSpace.value = entry.contentRect;
      });
    });

    /* Listens for changes in either the source or the available space above to determine the new canvasSize basis values */
    watch([canvasAvailableSpace, () => props.sourceSize, () => props.zoomLevel, canvasInteracting], () => {
      if (canvasAvailableSpace.value != null && props.sourceSize != null){
        setCanvasSize(props.sourceSize.width, props.sourceSize.height, canvasAvailableSpace.value.width, canvasAvailableSpace.value.height, props.zoomLevel);
      }
    }); 

    /* Sets the canvas size based on the available space and the source material while preserving the aspect ratio of the source */
    const setCanvasSize = function(sourceWidth, sourceHeight, availableWidth, availableHeight, selectedZoom){
      if (canvasInstance.value != null){
        /* Calculate scrollPosition of the viewport center (add half of viewport to get center from top left position) in percent based on current position 
        and size to later apply same scroll percentage to new size */
        let scrollCenterPercentageX = ((canvasContainer.value.scrollX || canvasContainer.value.scrollLeft) + (0.5 * availableWidth) ) / canvasInstance.value.width;
        let scrollCenterPercentageY = ((canvasContainer.value.scrollY || canvasContainer.value.scrollTop) + (0.5 * availableHeight) ) / canvasInstance.value.height;

        /* Calculate ratios to determine smaller space */
        let hRatio = availableWidth / sourceWidth;
        let vRatio = availableHeight / sourceHeight;
        
        /* Horizontal Ratio is smaller, which means this is the direction, where there is the least space. The biggest dimension has to fit in the smallest space.
        In this direction the edges are against the edges of the parents. In the other direction there will be space and has to be scaled to fit the aspect ratio.
        Calculate space to use based on smaller part and the target ratio. The bigger part is set as is. */
        if (hRatio < vRatio) {
          let sourceRatio = sourceHeight / sourceWidth;
          canvasInstance.value.width = availableWidth;
          canvasInstance.value.height = availableWidth * (sourceRatio);
        } 
        /* Vertical Ratio is smaller, which means this is the direction, where there is the least space. The biggest dimension has to fit in the smallest space.
        In this direction the edges are against the edges of the parents. In the other direction there will be space and has to be scaled to fit the aspect ratio.
        Calculate space to use based on smaller part and the target ratio. The bigger part is set as is. */
        else if (vRatio < hRatio) {
          let sourceRatio = sourceWidth / sourceHeight;
          canvasInstance.value.width = availableHeight * (sourceRatio);
          canvasInstance.value.height = availableHeight;
        } 
        /* Both Ratios are identical, fits in square box. Just set the size. There is no space left in either direction. */
        else {
          canvasInstance.value.width = availableWidth;
          canvasInstance.value.height = availableHeight;
        }

        /* Apply zoom level */
        let zoomX = canvasInstance.value.width * selectedZoom;
        let zoomY = canvasInstance.value.height * selectedZoom;
        canvasInstance.value.width = zoomX;
        canvasInstance.value.height = zoomY;

        /* Adjust scroll so it stays in center view (subtract half of viewport to get top left from center position) */
        canvasContainer.value.scroll(
          (canvasInstance.value.width * scrollCenterPercentageX) - (0.5 * availableWidth),
          (canvasInstance.value.height * scrollCenterPercentageY) - (0.5 * availableHeight)
        );

        updateCanvas();
      }
    }

    /* Calculate the ratio between the source image and the scaled canvas in the view. 
    Can be used e.g. to determine the image coordinates based on the scaled canvas coordinates */
    const canvasSourceDestinationRatio = function() {
      let ratioX = canvasInstance.value.width / props.sourceSize.width;
      let ratioY = canvasInstance.value.height / props.sourceSize.height;
      return { ratioX: ratioX, ratioY: ratioY };
    }

    /* Refresh canvas when the selected marker changes */
    watch(selectedSkeletonMarkerKey, () => updateCanvas());

    /* Gets the closest marker to the given location, but only in the MARKER_INTERACTION_RADIUS */
    const getClosestMarkerAt = function(frame, canvasX, canvasY, ratio = canvasSourceDestinationRatio(), excludedKey = null){
      let closestMarkerKey;
      let closestMarkerDistance;

      //Get the nearest marker to where the interaction ended
      for (let [key, marker] of Object.entries(frame.skeleton)) {
        if (key !== excludedKey){
          let xDistance = Math.abs((marker.x * ratio.ratioX) - canvasX);
          let yDistance = Math.abs((marker.y * ratio.ratioY) - canvasY);
          let smallestDistance = Math.min(xDistance, yDistance);

          //Test for every marker if the distance (in image coordinates) is smaller than MARKER_INTERACTION_RADIUS
          if (xDistance < MARKER_INTERACTION_RADIUS && yDistance < MARKER_INTERACTION_RADIUS) {
            //Set the currently closest if none is set or it is closer to the interaction
            if (!closestMarkerDistance || smallestDistance < closestMarkerDistance){
              closestMarkerKey = key;
              closestMarkerDistance = smallestDistance;
            }
          }
        }
      }

      return closestMarkerKey;
    }

    /* Calculate the coordinates inside the canvas based on where on the client window the gloabl coordinates are */
    const getCanvasCoordsFromClientCoords = function(clientX, clientY){
      let canvasBoundary = canvasInstance.value.getBoundingClientRect();

      let x;
      //Handle edge cases in ifs
      if (clientX < canvasBoundary.left) { //Outside left boundary
        x = 0;
      } else if (clientX > canvasBoundary.right) { //Outside right boundary
        x = canvasBoundary.width;
      } else { //Inside boundary
        x = clientX - canvasBoundary.left;
      }

      let y;
      //Handle edge cases in ifs
      if (clientY < canvasBoundary.top) { //Outside top boundary
        y = 0;
      } else if (clientY > canvasBoundary.bottom) { //Outside bottom boundary
        y = canvasBoundary.height;
      } else { //Inside boundary
        y = clientY - canvasBoundary.top;
      }

      return {
        x,
        y
      }
    }

    /* Save the timeout to cancel it, if the interaction gets interrupted */
    var selectTimeout = null;

    /* Cancel the saved timeout */
    const resetSelectTimeout = function(){
      if (selectTimeout !== null) clearTimeout(selectTimeout);
      selectTimeout = null;
    }

    /* Catch Start of Click or Movement in canvas to get the marker where the interaction started
    and set a timeout for holding a marker to select it. Also set data later used by other callbacks in this gesture */
    const canvasStartInteract = function(detail){
      //Only catch event, if a frame is selected
      if (props.enableInteraction){
        let ratio = canvasSourceDestinationRatio();

        let canvasCoords = getCanvasCoordsFromClientCoords(detail.startX, detail.startY);

        //Set data with the closest marker on start, the frame and the current ratio - Can be read in any other callback
        detail.data = { 
          closestMarkerKeyAtStart: getClosestMarkerAt(props.currentFrameObject, canvasCoords.x, canvasCoords.y, ratio),
          frame: props.currentFrameObject,
          ratio: ratio
        }

        //If a marker key was at the starting point of this interaction, add a class to the container to signal the UI to e.g. not scroll
        if (detail.data.closestMarkerKeyAtStart){
          canvasInteracting.value = true;
        }
        
        //Only possibly select this marker, if a valid marker key and it is not already selected
        if (detail.data.closestMarkerKeyAtStart && selectedSkeletonMarkerKey.value !== detail.data.closestMarkerKeyAtStart){
          //Use a timer to determine if it has been pressed long enough to change selection state while still held. 
          //Can be cancelled by early interaction end or movement outside of marker.
          selectTimeout = setTimeout(() => {
            store.dispatch('skeleton/setSelectedSkeletonMarkerKey', detail.data.closestMarkerKeyAtStart);
            updateCanvas();
          }, LONG_PRESS_THRESHOLD);
        }
      }
    }

    /* Catch movement of cursor in canvas for moving marker or cancelling select timeout for holding */
    const canvasMoveInteract = function(detail){
      //Only catch event, if a frame is selected
      if (props.enableInteraction){
         //If the cursor moved outside of the marker the select is cancelled
        if (detail.deltaX > MARKER_RADIUS || detail.deltaY > MARKER_RADIUS){
          resetSelectTimeout();
        }

        //If the selected marker gets dragged, move it to its new location
        if (selectedSkeletonMarkerKey.value === detail.data.closestMarkerKeyAtStart) {
          let canvasCoords = getCanvasCoordsFromClientCoords(detail.currentX, detail.currentY);

          //Exclude own marker in search to get other markers he collides with
          let closestMarkerKey = getClosestMarkerAt(detail.data.frame, canvasCoords.x, canvasCoords.y, detail.data.ratio, selectedSkeletonMarkerKey.value);

          let imageX = canvasCoords.x / detail.data.ratio.ratioX;
          let imageY = canvasCoords.y / detail.data.ratio.ratioY;

          //Prevent movement if in another marker (not defined) - thus prevent overlapping
          if(!closestMarkerKey){
            context.emit('movepoint', {
              frame: detail.data.frame,
              key: detail.data.closestMarkerKeyAtStart,
              x: Math.round(imageX),
              y: Math.round(imageY)
            });
          }
        }
      }
    }

    /* Catch end of click. Only reacts to short clicks */
    const canvasEndInteract = function(detail){
      //Always remove the class for UI interaction signal, even if interaction is disabled, as a fallback
      canvasInteracting.value = false;

      //Only catch event, if a frame is selected
      if (props.enableInteraction){
        //Stop timeout on interaction end, if it has not finished yet, the holding time was not enough
        resetSelectTimeout();
        
        let canvasCoords = getCanvasCoordsFromClientCoords(detail.currentX, detail.currentY);
        
        let closestMarkerKey = getClosestMarkerAt(detail.data.frame, canvasCoords.x, canvasCoords.y, detail.data.ratio);

        let imageX = canvasCoords.x / detail.data.ratio.ratioX;
        let imageY = canvasCoords.y / detail.data.ratio.ratioY;

        if ((detail.currentTime - detail.startTime) < LONG_PRESS_THRESHOLD) {
          //On short click, set a point if there is no existing one in radius, a marker type is selected for placement and it has not yet been placed
          if (!closestMarkerKey && selectedSkeletonMarkerKey.value !== null && !(selectedSkeletonMarkerKey.value in detail.data.frame.skeleton)){
            context.emit('addpoint', {
              frame: detail.data.frame,
              key: selectedSkeletonMarkerKey.value, 
              x: Math.round(imageX),
              y: Math.round(imageY),
              marker: store.getters['skeleton/getSelectedSkeletonMarker']
            });
          }
        }
      }
    }

    onMounted(() => {
      //Gesture to detect clicks and movement
      const gesture = createGesture({
        el: canvasInstance.value,
        threshold: 0,
        direction: undefined,
        onStart: (detail) => { canvasStartInteract(detail) },
        onMove: (detail) => { canvasMoveInteract(detail) },
        onEnd: (detail) => { canvasEndInteract(detail) }
      });
      gesture.enable();

      //Clear canvas on load
      clearCanvas();

      canvasResizeObserver.observe(canvasSizeReference.value);
    });

    return {
      canvasContainer,
      canvasInstance,
      canvasSizeReference,
      canvasInteracting,
      updateCanvas
    }

  }
}
</script>

<!-- This element automatically fills a relative parent to the maximum! -->
<style scoped>
/* Reference independent of any children or siblings. Canvas gets adjusted to this size in js. */
#canvas-size-reference {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
}

/* Canvas that gets its size from the above mentioned reference. */
canvas {
  z-index: 10;
}

#canvas-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* Center video in screen */
  text-align: center;
  overflow: auto;
}

/* Hide scrollbars and disable scrolling while a point is moved or interacted with in any way
  Only allow scrolling when not touching any of the points */
#canvas-container.interacting {
  overflow: hidden;
}

</style>
